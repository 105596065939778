import React from 'react';
import PrivatePage from '../layouts/PrivatePage';
import PageHeroHeader from '../components/PageHeroHeader';
import { Card, CardContainer } from '../components/Card';
import { EllipsisHeading } from '../sections/Writing';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Text } from 'rebass/styled-components';
import { PublishedCourse } from '../model/publishedCourse';
import { PortalAboutQuery } from '../model/portalAboutQuery';
import { useUserContext } from '../services/user/userContext';
import { LoggedIn } from '../services/user/UserState';

const CourseCard: React.FC<PublishedCourse> = ({ ...props }) => {
  return (
    <Link to={props.slug} style={{ color: 'inherit', textDecoration: 'none' }}>
      <Card>
        <EllipsisHeading m={3} p={1}>
          {props.title}
        </EllipsisHeading>
        <GatsbyImage
          style={{ height: '250px' }}
          alt=""
          aria-hidden={true}
          image={getImage(props.image)!}
        />
        <Text m={3}>{props.description.description}</Text>
      </Card>
    </Link>
  );
};

const CourseGrid: React.FC<PortalAboutQuery> = ({ data }) => {
  const userState = useUserContext().state;
  const userCourses =
    userState instanceof LoggedIn ? userState.purchasedCourses : [];
  return (
    <CardContainer
      minWidth="300px"
      style={{ marginLeft: '40px', marginRight: '40px' }}
    >
      {data.allContentfulAbout.nodes[0].videoPortalPublishedCourses
        .filter((course) => userCourses.includes(course.sku))
        .map((course) => (
          <CourseCard key={course.id} {...course} />
        ))}
    </CardContainer>
  );
};

const Portal: React.FC<PortalAboutQuery> = ({ data }) => {
  return (
    <PrivatePage
      title={data.allContentfulAbout.nodes[0].videoPortalTitle}
      description={data.allContentfulAbout.nodes[0].videoPortalDescription}
      featureImageUrl={
        data.allContentfulAbout.nodes[0].videoPortalOpenGraphImage.file.url
      }
    >
      <PageHeroHeader>
        {data.allContentfulAbout.nodes[0].videoPortalTitle}
      </PageHeroHeader>
      <CourseGrid data={data} />
    </PrivatePage>
  );
};

export const query = graphql`
  query VidePortalAbout {
    allContentfulAbout(sort: { fields: createdAt, order: DESC }, limit: 1) {
      nodes {
        videoPortalTitle
        videoPortalDescription
        videoPortalOpenGraphImage {
          file {
            url
          }
        }
        videoPortalPublishedCourses {
          id
          title
          sku
          slug
          description {
            description
          }
          image {
            gatsbyImageData(
              height: 250
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default Portal;
