import React, { CSSProperties, ElementType } from 'react';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import LinkAnimated from './LinkAnimated';

interface ContainerProps {
  readonly Background?: React.ReactNode;
  readonly paddingRight?: string;
  readonly paddingLeft?: string;
}

const SectionContainer: React.FC<
  Omit<ContainerProps, 'Background'>
> = styled.div<Omit<ContainerProps, 'Background'>>`
  min-height: 100vh;
  min-width: 320px;
  max-width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '1em')};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '1em'};
  scroll-behavior: smooth;
`;

const DefaultBackground: React.FC = () => <div />;

const Container = ({
  paddingRight,
  paddingLeft,
  children,
  Background = DefaultBackground,
}) => (
  <div>
    <Background />
    <SectionContainer paddingLeft={paddingLeft} paddingRight={paddingRight}>
      {children}
    </SectionContainer>
  </div>
);

interface HeaderProps {
  readonly name: string;
  readonly icon?: string;
  readonly label?: string;
  readonly as?: ElementType;
  readonly href?: string;
  readonly target?: string;
  readonly style?: CSSProperties;
  readonly id?: string;
}

const Header: React.FC<HeaderProps> = ({
  name,
  icon = '',
  label = '',
  as = 'h2',
  href,
  target,
  style,
  id
}) => (
  <Text
    id={id}
    style={style}
    color="textColor"
    fontSize={5}
    mb={4}
    as={as}
    href={href}
    target={target}
  >
    <LinkAnimated selected style={{ cursor: style?.cursor }}>
      {label || name}

      {icon && (
        <span role="img" aria-label={label} style={{ marginLeft: '10px' }}>
          {icon}
        </span>
      )}
    </LinkAnimated>
  </Text>
);

export default {
  Container,
  Header,
};
