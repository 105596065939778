import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Background = styled(GatsbyImage)`
  object-fit: cover;
  max-height: 40vh;
  width: 100%;
`;

const ChildrenContainer = styled(Box)`
  background-color: rgba(254, 239, 234, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 50%;
  max-width: 80vw;
  margin: auto;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PageHeroHeader = function ({ children, subtitle, subtitleComponent }) {
  return (
    <Box style={{ position: 'relative', marginBottom: '20px' }}>
      <StaticQuery
        query={graphql`
          query PageHeroHeaderQuery {
            file(relativePath: { eq: "sections/assets/texture.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `}
        render={(data) => (
          <Background
            image={getImage(data.file.childImageSharp)}
            alt="Women in Tech SEO"
          />
        )}
      />
      <ChildrenContainer>
        <Text as="h1" color="textColor" fontSize={[3, 5, 6]}>
          {children}
        </Text>
        {subtitle && (
          <Text
            as="p"
            color="textColor"
            fontWeight="bolder"
            fontSize={[2, 4, 4]}
          >
            {subtitle}
          </Text>
        )}
        {subtitleComponent && subtitleComponent}
      </ChildrenContainer>
    </Box>
  );
};

PageHeroHeader.propTypes = {
  children: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitleComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageHeroHeader;
