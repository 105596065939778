import React from 'react';
import { Text } from 'rebass/styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Section from '../components/Section';
import { CardContainer, Card } from '../components/Card';
import ImageSubtitle from '../components/ImageSubtitle';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks';
import NodeContent from '../model/NodeContent';
import richTextRenderer from '../components/RichTextRenderer';

const StyledCard = styled(Card)`
  width: 100%;
`;

export const EllipsisHeading = styled(Text).attrs({
  as: 'h2',
  fontWeight: 'bolder',
})`
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
  text-overflow: ellipsis;
  font-size: 24px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border-bottom: ${(props) => props.theme.colors.primary} 5px solid;
`;

export interface PostProps {
  readonly headerImage: ImageDataLike;
  readonly title: string;
  readonly description: NodeContent | null;
  readonly updatedAt: string;
}

export const PostWithoutDescription: React.FC<
  Omit<PostProps, 'description'>
> = ({ title, headerImage, updatedAt }) => {
  const img = getImage(headerImage);
  return (
    <StyledCard pb={4}>
      {img && (
        <GatsbyImage
          style={{ borderRadius: '8px 8px 0 0' }}
          alt={title}
          image={img}
        />
      )}
      <Text m={3} fontSize={[2, 3]}>
        {title}
      </Text>
      <ImageSubtitle bg="primary" color="white" x="right" y="bottom" round>
        {`${updatedAt}`}
      </ImageSubtitle>
    </StyledCard>
  );
};

export const Post: React.FC<PostProps> = ({
  title,
  description,
  headerImage,
  updatedAt,
}) => {
  const img = getImage(headerImage);
  return (
    <StyledCard pb={4}>
      <EllipsisHeading m={3} p={1}>
        {title}
      </EllipsisHeading>
      {img && <GatsbyImage alt={title} image={img} />}
      {description != null && (
        <Text m={3} fontSize={[2, 3]}>
          {richTextRenderer(description.raw)}
        </Text>
      )}
      <ImageSubtitle bg="primary" color="white" x="right" y="bottom" round>
        {`${updatedAt}`}
      </ImageSubtitle>
    </StyledCard>
  );
};

const edgeToArray = (data) => data.edges.map((edge) => edge.node);

const Writing = () => (
  <StaticQuery
    query={graphql`
      query MostRecentBlogsDescription {
        allContentfulBlogPost(
          limit: 6
          sort: { fields: updatedAt, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              description {
                raw
              }
              headerImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              updatedAt(formatString: "MMM YYYY")
            }
          }
        }
      }
    `}
    render={({ allContentfulBlogPost }) => {
      const posts = edgeToArray(allContentfulBlogPost);
      return (
        <section style={{ marginBottom: '40px' }}>
          <Section.Header
            name="Blog"
            as="a"
            href={'/blog/'}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
          />
          <CardContainer style={{ marginTop: '40px' }} minWidth="300px">
            {posts.map(({ ...rest }) => (
              <Link
                key={rest.id}
                style={{
                  width: '100%',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
                to={`/blog/${rest.slug}`}
              >
                <Post
                  key={rest.id}
                  title={rest.title}
                  description={rest.description}
                  headerImage={rest.headerImage}
                  updatedAt={rest.updatedAt}
                />
              </Link>
            ))}
          </CardContainer>
        </section>
      );
    }}
  />
);

export default Writing;
